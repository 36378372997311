<template>
    <img :src="data.src" :alt="data.alt"/>
  </template>

  <script>
  export default {
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
  };
  </script>