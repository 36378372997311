<template>
    <iframe
      :src="data.src"
      width="100%"
      height="500px"
      frameborder="0"
      allow="autoplay; encrypted-media"
      title="Iframe Content"
    ></iframe>
  </template>

  <script>
  export default {
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
  };
  </script>