<template>
  <div class="home">

    <section class="hero-section" id="hero">
      <div class="hero-content">
        <div class="container">
          <div class="row">
            <div class="col col-md-12">
              <WavingEmoji />
              <h1>{{ fname }} {{ lname }}</h1>
            </div>
          </div>
          <div class="row">
            <div class="col col-sm-8 col-md-7">
              <div class="intro">
                <p>I create user-centered digital experiences that are both intuitive and impactful. I’ve had the privilege
                  of working with startups and large companies across different industries. With a background in engineering
                  and strong visual skills, I ensure that my designs deliver real value to users and businesses alike.</p>
                <!-- <p>Welcome! <a href="#design-system">Take a look</a> at some of the projects I’ve worked on.</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="projects container">
      <div
        v-for="project in projects"
        :key="project.name"
        :id="project.id"
        class="project row"
      >
        <!-- Text Column: Contains project title, description, and links -->
        <div class="text-column col col-sm-4 col-md-3">
          <div class="text">
            <div v-if="project.preText" class="pre-text">{{ project.preText }}</div>
            <h2 v-if="project.title">{{ project.title }}</h2>
            <div v-if="project.description" v-html="project.description"></div>
            <p>
              <!-- Main link to project details -->
              <router-link v-if="project.link" :to="project.link" class="project-link">
                {{ project.linkText || 'View Project' }}
              </router-link>

              <!-- Link to download a PDF if available -->
              <a v-if="typeof project.secondLink === 'string' && project.secondLink.endsWith('.pdf')"
                :href="project.secondLink"
                target="_blank"
                rel="noopener noreferrer"
                class="project-link">
                {{ project.secondLinkText || 'Download PDF' }}
              </a>

              <!-- Mailto link for email contact -->
              <a v-else-if="typeof project.secondLink === 'string' && project.secondLink.startsWith('mailto:')"
                :href="project.secondLink"
                class="project-link">
                {{ project.secondLinkText || 'More Info' }}
              </a>

              <!-- Optional text (can be used for additional notes) -->
              <span v-if="project.optionalText" class="optional-text">
                {{ project.optionalText }}
              </span>
            </p>
          </div>
        </div>

        <div class="image-column col col-sm-8 col-md-9">
          <!-- Use dynamic component for rendering content -->
          <component :is="project.activeComponent" :data="project.activeContent" />
        </div>
      </div>
    </div>

  </div>

<!--

Here’s to the dreamers, the doers, and everyone in between.
Keep pushing forward, and don’t forget to enjoy the journey. 🍺

Stan

-->

</template>

<script>
import { siteConfig } from '@/config';
import WavingEmoji from './WavingEmoji.vue';
import ImageContent from './ImageContent.vue';
import IframeContent from './IframeContent.vue';


export default {
  name: "HomePage",
  components: {
    WavingEmoji,
    ImageContent,
    IframeContent,
  },
  data() {
    return {
      fname: siteConfig.fname,
      lname: siteConfig.lname,
      email: siteConfig.email,
      jobTitle: siteConfig.jobTitle,
      projects: []
    };
  },
  created() {
    this.projects = [
      {
        id: "athome",
        preText: "Creating trust through thoughtful design.",
        title: "Website Design for At House",
        description: `<p>I led the design of At House's new website, focusing on enhancing the user experience and aligning it with the brand's
          vision. My role included developing an intuitive layout that guides users seamlessly through the site.</p><p>The goal was to build trust,
          increase conversions, and simplify the customer journey. By combining clean visuals with strategic design decisions, the site empowers
          visitors to take action and engage confidently with At House's offerings.</p>`,
        activeComponent: "IframeContent",
        activeContent: { src: "https://www.figma.com/embed?embed_host=code&url=https://www.figma.com/proto/Mx0s9SOb90FYfYWo5xc2hI/At-House?node-id=144-552&node-type=canvas&t=LKSXu0HVBSs0pkEX-1&scaling=scale-down&content-scaling=responsive&page-id=144%3A994&starting-point-node-id=144%3A552&hide-ui=1&client-id=a4UfYvF31v8HVeERU0XuUP" },
      },
      {
        id: "design-system",
        preText: "Building consistency across products with a unified design approach.",
        title: "Design System for DTN",
        description: `<p>As the primary designer, I developed DTN’s design system, including principles, components, and standards to unify its
          product suite. I worked closely with teams to ensure consistency and usability across diverse applications.</p><p>The strategy was to
          create a scalable system that boosts efficiency, reduces redundancy, and delivers a seamless user experience across agriculture, energy,
          and weather products.</p>`,
        optionalText: "More details soon",
        activeComponent: "ImageContent",
        activeContent: { src: require("@/assets/images/dtn/ds-full.png"), alt: "Example Image" },
      },
      {
        id: "b2b-products",
        preText: "Designing solutions tailored for diverse industries.",
        title: "DTN Products Design",
        description: `<p>I contributed to designing various DTN products, collaborating across teams and leading efforts on the Fuel and Ag
          Intelligence Hub projects. My focus was on delivering impactful, user-centered solutions tailored to sector-specific needs.</p><p>The design
            approach prioritized functionality and user engagement, ensuring that products align with both technical requirements and business
            objectives. This created a consistent, intuitive experience for diverse industry users.</p>`,
        activeComponent: "IframeContent",
        activeContent: { src: "https://www.figma.com/embed?embed_host=code&url=https://www.figma.com/proto/G4L5bezdOWufY9Fy3dSVsl/DTN-Apps?node-id=1-2500&node-type=frame&t=pBeqyb1oAvaYDBkz-1&scaling=scale-down&content-scaling=responsive&page-id=0%3A1&starting-point-node-id=1%3A2500&hide-ui=1&client-id=a4UfYvF31v8HVeERU0XuUP" },
      },
      {
        id: "room-control",
        preText: "Luxury meets simplicity with intuitive room controls.",
        title: "Studio City Macau App Design",
        description: `<p>I led the design of a brand-new app for Studio City Macau, focusing on delivering a luxury digital experience. My role
          involved crafting a cohesive user interface that aligns with the hotel's cinematic and opulent branding.</p><p>The strategy centered on
            enhancing guest convenience through intuitive room controls, seamless access to services, and integrated e-commerce features. The app
            elevates the guest journey while reflecting the prestige of the Studio City brand.</p>`,
        activeComponent: "IframeContent",
        activeContent: { src: "https://embed.figma.com/proto/iFroe9NY8LD3BSbcQ8IWis/Studio-City?node-id=6-3246&node-type=frame&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=8%3A4692&show-proto-sidebar=1&embed-host=share&hide-ui=1&client-id=a4UfYvF31v8HVeERU0XuUP" },
      },
      // {
      //   id: "website-app",
      //   title: "ExpressVPN",
      //   preText: "ExpressVPN",
      //   description: "Pioneered the UI design and engineering for ExpressVPN’s marketing website, crafting a durable and user-friendly interface that has remained effective and successful over the years.",
      //   optionalText: "expressvpn.com",
      //   // link: "https://expressvpn.com",
      //   // linkText: "expressvpn.com",
      //   image: require('@/assets/images/expressvpn/device.png'),
      //   imageClass: 'laptop',
      // },
      // {
      //   id: "concepts",
      //   title: "Concepts collection",
      //   preText: "Exploring possibilities",
      //   description: "A collection of concept designs showcasing innovative ideas and creative solutions, compiled to inspire and explore new possibilities in design.",
      //   optionalText: "Coming soon",
      //   image: require('@/assets/images/dating/s3.png'),
      //   imageClass: 'mobile',
      // },
      // {
      //   id: "banking-app",
      //   title: "Banking app UI",
      //   preText: "Avaloq",
      //   description: "Improved the usability and interface of a bank app by streamlining navigation, simplifying transactions, and enhancing accessibility, creating a more user-friendly experience.",
      //   link: "/work/banking-app",
      //   linkText: "View project",
      //   image: require('@/assets/images/avq/avq1.png'),
      //   imageClass: 'laptop',
      // },
      // {
      //   id: "mall-navigator",
      //   title: "Mall navigator",
      //   preText: "Yoho Mall - Hong Kong",
      //   description: "A customized mall map with store promotions, PWD paths, and quick access to customer service for easy navigation.",
      //   link: "/work/mall-navigator",
      //   linkText: "Explore the map",
      //   // secondLinkText: "Complete user flow",
      //   // secondLink: "/assets/pdf/yoho-navigator-user-flow.pdf", // Link to PDF in the public folder
      //   image: require('@/assets/images/yoho/yoho1.png'),
      //   imageClass: 'mobile',
      // },
      // {
      //   id: "archive",
      //   title: "A look back",
      //   preText: "From the Archives",
      //   description: "Explore a curated collection of past work, highlighting the early stages of my design journey and the evolution of style, technique, and creativity over the years.",
      //   optionalText: "Coming soon",
      //   image: require('@/assets/images/introme-app3.png'),
      //   imageClass: 'mobile',
      // },
      {
        id: "info",
        title: 'Let’s Connect',
        preText: '',
        description: `<p>If you’re curious about collaborating or have any questions, feel free to reach out. I’m always excited to discuss ideas,
          explore opportunities, and create something exceptional together.</p>`,
        link: "/resume",
        linkText: "More about me",
        secondLink: `mailto:${this.email}`,
        secondLinkText: `${this.email}`,
        activeComponent: "ImageContent",
        activeContent: { src: require("@/assets/images/stan.jpg"), alt: "Stan" },
        imageClass: 'photo',
      },
    ];
  },
  mounted() {
    this.setupObserver();
    this.restoreScrollPosition();
  },
  methods: {
    setupObserver() {
      const options = {
        root: null, // In the viewport
        rootMargin: '0px',
        threshold: 0.5 // Trigger when 50% of the project is visible
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const projectId = entry.target.id;
            // Save the current project ID to the sessionStorage
            sessionStorage.setItem('currentProjectId', projectId);
          }
        });
      }, options);

      // Observe each project element
      this.$nextTick(() => {
        const projectElements = document.querySelectorAll('.project');
        projectElements.forEach((project) => {
          observer.observe(project);
        });
      });
    },

    restoreScrollPosition() {
      const savedProjectId = sessionStorage.getItem('currentProjectId');
      if (savedProjectId) {
        this.$nextTick(() => {
          const targetElement = document.getElementById(savedProjectId);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const currentProjectId = sessionStorage.getItem('currentProjectId');
    if (currentProjectId) {
      sessionStorage.setItem('savedProjectId', currentProjectId); // Save the project to return to
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const savedProjectId = sessionStorage.getItem('savedProjectId');
      if (savedProjectId) {
        vm.$nextTick(() => {
          const targetElement = document.getElementById(savedProjectId);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    });
  }
};
</script>